import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Skeleton, Row, Empty, Col, Space } from 'antd';
import { TbUpload, TbSend } from 'react-icons/tb';
import { IoCloseOutline } from 'react-icons/io5';
import {
  mentionCommentParser,
  getMentionUuidFromNode,
} from 'src/utils/stringHelpers';
import {
  UploadFileApi,
  UserResponse,
  UploadResponse,
  UploadS3Response,
} from 'src/types';
import useComments from './useComment';
import CommentMessage from '../CommentMessage';
import FileUploadModal from '../FileUpload';
import RichTextEditor from '../RichTextEditor';

interface CommentProps {
  typeUuid: string;
  type: 'tasks' | 'projects' | 'requests';
  projectUuid: string;
  scrollClassName?: string;
  showCommentType?: boolean;
  onScrollIntoView?: any;
  containerInputClass?: string;
  onInitialLoaded?: () => void;
  onFinishSubmit?: (values: any, images: UploadFileApi[]) => void;
  onFilesDelete?: (uuid: string[]) => void;
}

const Comment: FC<CommentProps> = ({
  typeUuid,
  type,
  projectUuid,
  scrollClassName,
  showCommentType,
  onScrollIntoView,
  onInitialLoaded,
  onFinishSubmit,
  onFilesDelete,
  containerInputClass = '',
}) => {
  const {
    inputRef,
    state,
    scrollRef,
    targetCommentId,
    setComment,
    handleUpdateComment,
    handleDeleteComment,
    handleAddComments,
    loadMore,
    handleDeleteUpload,
    handleUpdateReply,
    handleDeleteReply,
    handleAddReply,
    setReplyTo,
    handleAddReaction,
    handleRemoveReaction,
    handleRemoveReplyReaction,
    handleAddReplyReaction,
  } = useComments({
    typeUuid,
    type,
    onScrollIntoView,
    onInitialLoaded,
    onFilesDelete,
  });

  const { data, loading, adding, replyTo, replyToUuid, hasMore } = state;
  const [images, setImages] = useState<UploadFileApi[]>([]);
  const [visible, setVisible] = useState(false);
  const [loadingImages, setLoadingImages] = useState(0);

  const user: UserResponse = useSelector(
    (globalState: any) => globalState.auth.user
  );

  if (loading && data?.length === 0) {
    return <Skeleton className="py-4" />;
  }

  const handleAddImages = (uploads: UploadFileApi[]) => {
    setImages([...images, ...uploads]);
    setVisible(false);
  };

  const handleDeleteImage = (uuid: string) => {
    setImages([...images?.filter((el) => el.response?.uuid !== uuid)]);
  };

  const handleSubmit = () => {
    const comment = inputRef.current?.getQuill()?.getEditorContents() as string;

    if (!comment) return;

    const values = {
      text: comment,
      related_users: getMentionUuidFromNode(comment),
      type: 'comment',
      uploads: images.map((el) => el.response?.uuid),
    };

    const statedImages: UploadFileApi[] = images.map((file) => {
      return {
        ...file,
        response: {
          ...file.response,
          stated: true,
        } as UploadS3Response,
      };
    });

    if (replyTo && replyToUuid) {
      handleAddReply(replyToUuid, values, () => {
        setComment('');
        setImages([]);
        setReplyTo(null, null);
        onFinishSubmit?.(values, statedImages);
      });
    } else {
      handleAddComments(values, () => {
        setComment('');
        setImages([]);
        onFinishSubmit?.(values, statedImages);
      });
    }
  };

  return (
    <>
      <FileUploadModal
        onOk={handleAddImages}
        visible={visible}
        closeUploadModal={() => setVisible(false)}
      />

      {!loading && data?.length === 0 ? (
        <Empty
          className={`py-6`}
          description={<span className="text-gray-400">No comments</span>}
        />
      ) : (
        <div
          ref={scrollRef}
          className={`w-full overflow-y-auto ${scrollClassName}`}
        >
          {hasMore && (
            <div className="text-center">
              <Button loading={loading} onClick={loadMore} type="primary">
                {loading ? 'Loading....' : 'Load more'}
              </Button>
            </div>
          )}

          <div className="flex flex-col gap-2">
            {data?.map((comment) => {
              return (
                <CommentMessage
                  key={comment.uuid}
                  comment={comment}
                  user={user}
                  targetCommentId={targetCommentId}
                  projectUuid={projectUuid}
                  showCommentType={showCommentType}
                  onReplyTo={setReplyTo}
                  onImgDelete={handleDeleteUpload}
                  onAddReaction={handleAddReaction}
                  onRemoveReaction={handleRemoveReaction}
                  onDelete={async (uuid) => {
                    await handleDeleteComment(uuid);
                    onFilesDelete?.(
                      comment.uploads.map((item: UploadResponse) => item.uuid)
                    );
                  }}
                  onUpdate={handleUpdateComment}
                >
                  {comment.replies?.map((reply) => {
                    return (
                      <CommentMessage
                        key={reply.uuid}
                        parentEl={comment}
                        comment={reply}
                        user={user}
                        targetCommentId={targetCommentId}
                        projectUuid={projectUuid}
                        showCommentType={showCommentType}
                        onReplyTo={setReplyTo}
                        onImgDelete={handleDeleteUpload}
                        onAddReaction={handleAddReplyReaction}
                        onRemoveReaction={handleRemoveReplyReaction}
                        onDelete={async (uuid, parentUuid) => {
                          await handleDeleteReply(uuid, parentUuid);
                          onFilesDelete?.(
                            comment.uploads.map(
                              (item: UploadResponse) => item.uuid
                            )
                          );
                        }}
                        onUpdate={handleUpdateReply}
                      />
                    );
                  })}
                </CommentMessage>
              );
            })}
          </div>
        </div>
      )}

      <div className={`w-full pt-3 ${containerInputClass}`}>
        <div className="flex flex-wrap gap-x-3">
          {images?.map((el) => {
            return (
              <div
                key={el.response?.uuid}
                className="mb-2 rounded bg-gray-200 px-2 py-1"
              >
                <Space align="center">
                  {el.name}
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() => handleDeleteImage(el.response?.uuid || '')}
                  />
                </Space>
              </div>
            );
          })}
        </div>

        {replyTo && (
          <div className="mb-3">
            <div className="replyTo">
              <div className="line" />
              <Row
                justify="space-between"
                gutter={16}
                wrap={false}
                align="middle"
              >
                <Col flex={1}>
                  <h3>{replyTo.creator.name}</h3>

                  <RichTextEditor
                    className="comment-styles resume"
                    defaultValue={mentionCommentParser(replyTo.text)}
                    readonly
                    noContainer
                  />
                </Col>

                <Col>
                  <IoCloseOutline
                    className="cursor-pointer"
                    onClick={() => setReplyTo(null, null)}
                  />
                </Col>
              </Row>
            </div>
          </div>
        )}

        <div className="flex w-full items-end gap-2">
          <div className="mb-1 flex-none">
            <Button
              size="large"
              shape="circle"
              onClick={() => setVisible(true)}
              icon={<TbUpload />}
            />
          </div>

          <div className="flex-1">
            <RichTextEditor
              ref={inputRef}
              includeToolbar={false}
              includeMention
              projectId={projectUuid}
              scrollingContainer=".task-drawer .ant-drawer-body"
              placeholder="Add a comment"
              onImageUploader={async (upload) => {
                setImages((prev) => [...prev, upload]);
              }}
              onLoadingImage={setLoadingImages}
              onShortEnterKey={handleSubmit}
            />
          </div>

          <div className="mb-1 flex-none">
            <Button
              size="large"
              shape="circle"
              loading={adding}
              disabled={loadingImages > 0}
              onClick={handleSubmit}
              icon={
                <TbSend
                  className="block h-6 w-4 text-blue-600"
                  aria-hidden="true"
                />
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;
